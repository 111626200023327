import React, {useState, useCallback} from 'react'
import { playWaterphone } from '../snd/playSound';

import BorbEnter from './BorbEnter';
import Borbs from './Borbs';

export default function BorbContainer() {
  const [didEnter, setDidEnter] = useState(false);
  const onBorbEnter = useCallback(evt => {
    evt.preventDefault();
    setDidEnter(true);
    playWaterphone();
  }, []);
  return didEnter ? <Borbs /> : <BorbEnter onClick={onBorbEnter} />
}
