import React from "react";

export default function BorbEnter({
  onClick
}) {
  return (
    <>
      <header className="header"></header>
      <main className="borbs">
        <a onClick={onClick} href="#" className="borb-enter">
          Enter the world of the Borbs...
        </a>
      </main>
      <footer></footer>
    </>
  );
}
