import React, { useCallback, useEffect, useState } from "react";
import blueBorb from '../img/blue-borb.png';
import robinBorb from '../img/borb-robin.png';
import borb1 from '../img/borb1.png';
import { playWaterphone } from "../snd/playSound";

export default function Borbs() {
  const [derender, setDerender] = useState(false);
  const [showReplay, setShowReplay] = useState(false);

  const replay = useCallback(() => {
    setDerender(true);
    playWaterphone();
    setShowReplay(false);
  }, []);

  useEffect(() => {
    if (derender) {
      setDerender(false);
    }
  }, [derender]);

  useEffect(() => {
    const timeout = setTimeout(() => { setShowReplay(true); }, 4000);
    return () => clearTimeout(timeout);
  }, [derender]);

  if (derender) {
    return null;
  }

  return (
    <>
      <header className="header"></header>
      <main className="borbs">
        <img className="borb blue-borb" src={blueBorb} />
        <img className="borb robin-borb" src={robinBorb} />
        <img className="borb big-borb" src={borb1} />
        <div className="overlay">
          <h1>BORBS</h1>
        </div>
        {showReplay && (<div className="replay" onClick={replay}>
          Do it again
        </div>)}
      </main>
      <footer></footer>
    </>
  );
}
